import { apiUrl } from '../../../../api';
import { PRIO } from '../../../../constants';
import { MailComment } from '../../../../models/Message';
import { getAccessToken } from '../../../../store/authEffect';
import fetchWithRetry from '../../../../util/fetchWithRetry';

export const FETCH_CONVERSATION_COMMENTS_REQUEST =
  PRIO + 'FETCH_CONVERSATION_COMMENTS_REQUEST';
export const FETCH_CONVERSATION_COMMENTS_COMMIT =
  PRIO + 'FETCH_CONVERSATION_COMMENTS_COMMIT';
export const FETCH_CONVERSATION_COMMENTS_ROLLBACK =
  PRIO + 'FETCH_CONVERSATION_COMMENTS_ROLLBACK';

export const fetchConversationComments = (conversationId: string) => ({
  type: FETCH_CONVERSATION_COMMENTS_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      effect: {
        url: `${apiUrl}/email/EmailComment/conversation/${conversationId}`,
        method: 'GET',
      },
      commit: {
        type: FETCH_CONVERSATION_COMMENTS_COMMIT,
        meta: { conversationId },
      },
      rollback: {
        type: FETCH_CONVERSATION_COMMENTS_ROLLBACK,
        meta: { conversationId },
      },
    },
  },
});

const createActionTypes = (base: string) => ({
  REQUEST: PRIO + base + '_REQUEST',
  COMMIT: PRIO + base + '_COMMIT',
  ROLLBACK: PRIO + base + '_ROLLBACK',
});

const createCommentAction = (typeSet: any, commentId: string) => ({
  type: typeSet.REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      effect: {
        url: `${apiUrl}/email/EmailComment/${commentId}`,
        method: 'GET',
      },
      commit: {
        type: typeSet.COMMIT,
        meta: { commentId },
      },
      rollback: {
        type: typeSet.ROLLBACK,
        meta: { commentId },
      },
    },
  },
});

export const FETCH_COMMENT_BY_ID = createActionTypes('FETCH_COMMENT_BY_ID');
export const fetchCommentById = (commentId: string) =>
  createCommentAction(FETCH_COMMENT_BY_ID, commentId);

export const UPDATE_COMMENT_BY_ID = createActionTypes('UPDATE_COMMENT_BY_ID');
export const updateCommentById = (commentId: string) =>
  createCommentAction(UPDATE_COMMENT_BY_ID, commentId);

export const DELETE_COMMENT_BY_ID = createActionTypes('DELETE_COMMENT_BY_ID');
export const deleteCommentById = (commentId: string) => ({
  type: DELETE_COMMENT_BY_ID.COMMIT,
  payload: { commentId },
});

export const CREATE_COMMENT_REQUEST = PRIO + 'CREATE_COMMENT_REQUEST';
export const CREATE_COMMENT_COMMIT = PRIO + 'CREATE_COMMENT_COMMIT';
export const CREATE_COMMENT_ROLLBACK = PRIO + 'CREATE_COMMENT_ROLLBACK';

export const createComment = (comment) => {
  return async (dispatch) => {
    const result = await fetchWithRetry(`${apiUrl}/email/EmailComment`, {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(comment),
    });
    const data =
      result.status >= 200 && result.status < 300 ? await result.json() : null;

    const dataEmailConversationId = data?.emailConversationId;
    if (data) {
      dispatch({
        type: CREATE_COMMENT_COMMIT,
        meta: { comment },
        payload: { response: data },
      });
    } else {
      dispatch({
        type: CREATE_COMMENT_ROLLBACK,
        meta: { comment },
        error: data,
      });
    }

    return dataEmailConversationId;
  };
};

export const UPDATE_COMMENT_REQUEST = PRIO + 'UPDATE_COMMENT_REQUEST';
export const UPDATE_COMMENT_COMMIT = PRIO + 'UPDATE_COMMENT_COMMIT';
export const UPDATE_COMMENT_ROLLBACK = PRIO + 'UPDATE_COMMENT_ROLLBACK';

export const updateComment = (comment: MailComment) => ({
  type: UPDATE_COMMENT_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      effect: {
        url: `${apiUrl}/email/EmailComment/${comment.emailCommentId}`,
        method: 'PUT',
        json: comment,
      },
      commit: {
        type: UPDATE_COMMENT_COMMIT,
        meta: { comment },
      },
      rollback: {
        type: UPDATE_COMMENT_ROLLBACK,
        meta: { comment },
      },
    },
  },
});

export const DELETE_COMMENT_REQUEST = PRIO + 'DELETE_COMMENT_REQUEST';
export const DELETE_COMMENT_COMMIT = PRIO + 'DELETE_COMMENT_COMMIT';
export const DELETE_COMMENT_ROLLBACK = PRIO + 'DELETE_COMMENT_ROLLBACK';

export const deleteComment = (commentId: string) => ({
  type: DELETE_COMMENT_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      effect: {
        url: `${apiUrl}/email/EmailComment/${commentId}`,
        method: 'DELETE',
      },
      commit: {
        type: DELETE_COMMENT_COMMIT,
        meta: { commentId },
      },
      rollback: {
        type: DELETE_COMMENT_ROLLBACK,
        meta: { commentId },
      },
    },
  },
});
