import { PRIO } from '../../../../constants';
import { apiUrl } from '../../../../api';
import { MailFolderId, MessageId, ProjectId } from '../../../../models/Types';
import {
  FlagMessagePayload,
  Message,
  MessageAttachment,
  MessageCategorization,
} from '../../../../models/Message';
import { DispatchAction } from '../../../../models/Redux';

/** Contact fetching */
export const FETCH_MESSAGES_ME_REQUEST = PRIO + 'FETCH_MESSAGES_ME_REQUEST';
export const FETCH_MESSAGES_ME_COMMIT = PRIO + 'FETCH_MESSAGES_ME_COMMIT';
export const FETCH_MESSAGES_ME_ROLLBACK = PRIO + 'FETCH_MESSAGES_ME_ROLLBACK';

export const fetchMessagesMe = (
  mailFolderId: string,
  movingMessageIds: MessageId[] = [],
  nextLink?: string
) => ({
  type: FETCH_MESSAGES_ME_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/email/EmailMe/message/folder/${mailFolderId}/delta${
          nextLink ? `?nextLink=${nextLink}` : ''
        }`,
        method: 'GET',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: FETCH_MESSAGES_ME_COMMIT,
        meta: { mailFolderId, nextLink, movingMessageIds },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_MESSAGES_ME_ROLLBACK,
        snackbarErrorMessage: {
          label: 'mail:errorMessages.messages.fetchError',
          timeout: 6,
        },
        meta: { mailFolderId, nextLink, movingMessageIds },
      },
    },
    mailFolderId,
    nextLink,
    movingMessageIds,
  },
});

export const FETCH_MESSAGE_ME_REQUEST = PRIO + 'FETCH_MESSAGE_ME_REQUEST';
export const FETCH_MESSAGE_ME_COMMIT = PRIO + 'FETCH_MESSAGE_ME_COMMIT';
export const FETCH_MESSAGE_ME_ROLLBACK = PRIO + 'FETCH_MESSAGE_ME_ROLLBACK';

export const fetchMessageMe: (
  messageId: MessageId
) => DispatchAction<{ messageId: MessageId }, Message> = (messageId) => ({
  type: FETCH_MESSAGE_ME_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/email/EmailMe/message/${messageId}`,
        method: 'GET',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: FETCH_MESSAGE_ME_COMMIT,
        meta: { messageId },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_MESSAGE_ME_ROLLBACK,
        meta: { messageId },
      },
    },
    messageId,
  },
});

export const DELETE_MESSAGES_ME_REQUEST = PRIO + 'DELETE_MESSAGES_ME_REQUEST';
export const DELETE_MESSAGES_ME_COMMIT = PRIO + 'DELETE_MESSAGES_ME_COMMIT';
export const DELETE_MESSAGES_ME_ROLLBACK = PRIO + 'DELETE_MESSAGES_ME_ROLLBACK';

export const deleteMessagesMe = (
  mailFolderId: MailFolderId,
  messageIds: MessageId[]
) => ({
  type: DELETE_MESSAGES_ME_REQUEST,
  requiresAuth: true,
  payload: { messageIds },
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/email/EmailMe/message/delete`,
        method: 'DELETE',
        json: messageIds,
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: DELETE_MESSAGES_ME_COMMIT,
        meta: { mailFolderId, messageIds },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: DELETE_MESSAGES_ME_ROLLBACK,
        snackbarErrorMessage: {
          label: 'mail:errorMessages.messages.deleteMessagesError',
          timeout: 6,
        },
        meta: { mailFolderId, messageIds },
      },
    },
    mailFolderId,
    messageIds,
  },
});

export const SOFTDELETE_MESSAGES_ME_REQUEST =
  PRIO + 'SOFTDELETE_MESSAGES_ME_REQUEST';
export const SOFTDELETE_MESSAGES_ME_COMMIT =
  PRIO + 'SOFTDELETE_MESSAGES_ME_COMMIT';
export const SOFTDELETE_MESSAGES_ME_ROLLBACK =
  PRIO + 'SOFTDELETE_MESSAGES_ME_ROLLBACK';

export const softdeleteMessagesMe = (
  messageIds: MessageId[],
  rollbackMessages: Message[],
  mailFolderId?: MailFolderId
) => ({
  type: SOFTDELETE_MESSAGES_ME_REQUEST,
  requiresAuth: true,
  payload: { messageIds },
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/email/EmailMe/message/softdelete`,
        method: 'POST',
        json: messageIds,
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: SOFTDELETE_MESSAGES_ME_COMMIT,
        meta: { mailFolderId, rollbackMessages, messageIds },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: SOFTDELETE_MESSAGES_ME_ROLLBACK,
        snackbarErrorMessage: {
          label: 'mail:errorMessages.messages.deleteMessagesError',
          timeout: 6,
        },
        meta: { mailFolderId, rollbackMessages, messageIds },
      },
    },
    mailFolderId,
    rollbackMessages,
    messageIds,
  },
});

export const MOVED_MESSAGE_ME = PRIO + 'MOVED_MESSAGE_ME';

export const movedMessageMe = (
  messageIds: MessageId[],
  destinationId: MailFolderId,
  originId: MailFolderId
) => ({
  type: MOVED_MESSAGE_ME,
  payload: {
    messageIds,
    destinationId,
  },
  meta: {
    originId,
  },
});

export const UPDATE_MESSAGE_ME = PRIO + 'UPDATE_MESSAGE_ME';

export const updateMessageMe = (
  messageId: MessageId,
  messageUpdate: Partial<Message>
) => ({
  type: UPDATE_MESSAGE_ME,
  payload: {
    messageUpdate,
  },
  meta: {
    messageId,
  },
});

export const UPDATE_MESSAGES_ME = PRIO + 'UPDATE_MESSAGES_ME';

export const updateMessagesMe = (
  messageIds: MessageId[],
  messageUpdates: { messageId: MessageId; messageUpdate: Partial<Message> }[]
) => ({
  type: UPDATE_MESSAGES_ME,
  payload: {
    messageUpdates,
  },
  meta: {
    messageIds,
  },
});

export const CATEGORIZED_MESSAGE_ME = PRIO + 'CATEGORIZED_MESSAGE_ME';

export const categorizedMessageMe = (
  messages: MessageCategorization,
  isDelete: boolean
) => ({
  type: CATEGORIZED_MESSAGE_ME,
  payload: {
    messages,
    isDelete,
  },
});

export const MARK_AS_READ_MESSAGE_ME_REQUEST =
  PRIO + 'MARK_AS_READ_MESSAGE_ME_REQUEST';
export const MARK_AS_READ_MESSAGE_ME_COMMIT =
  PRIO + 'MARK_AS_READ_MESSAGE_ME_COMMIT';
export const MARK_AS_READ_MESSAGE_ME_ROLLBACK =
  PRIO + 'MARK_AS_READ_MESSAGE_ME_ROLLBACK';

export const markAsReadMe = (
  messageIds: MessageId[],
  isRead: boolean,
  mailFolderId: MailFolderId,
  changedUnreadItemCount: number
) => {
  const payload = messageIds.map((messageId) => ({ messageId, isRead }));
  return {
    type: MARK_AS_READ_MESSAGE_ME_REQUEST,
    requiresAuth: true,
    payload,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${apiUrl}/email/EmailMe/message/read`,
          method: 'PUT',
          json: { messages: payload },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: MARK_AS_READ_MESSAGE_ME_COMMIT,
          meta: { payload, mailFolderId, isRead, changedUnreadItemCount },
        },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: MARK_AS_READ_MESSAGE_ME_ROLLBACK,
          snackbarErrorMessage: {
            label: 'mail:errorMessages.messages.markAsReadError',
            timeout: 6,
          },
          meta: { payload },
        },
      },
    },
  };
};

export const FLAG_MESSAGE_ME_REQUEST = PRIO + 'FLAG_MESSAGE_ME_REQUEST';
export const FLAG_MESSAGE_ME_COMMIT = PRIO + 'FLAG_MESSAGE_ME_COMMIT';
export const FLAG_MESSAGE_ME_ROLLBACK = PRIO + 'FLAG_MESSAGE_ME_ROLLBACK';

export const flagMessageMe = (
  payload: FlagMessagePayload,
  originalPayload: FlagMessagePayload
) => ({
  type: FLAG_MESSAGE_ME_REQUEST,
  requiresAuth: true,
  payload,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/email/EmailMe/message/flag`,
        method: 'PUT',
        json: { messages: payload },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: FLAG_MESSAGE_ME_COMMIT,
        meta: { payload },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FLAG_MESSAGE_ME_ROLLBACK,
        snackbarErrorMessage: {
          label: 'mail:errorMessages.messages.flagMessageError',
          timeout: 6,
        },
        meta: { originalPayload },
      },
    },
  },
});

export const DELETE_LOCAL_MESSAGE_ME = PRIO + 'DELETE_LOCAL_MESSAGE_ME';

export const deleteLocalMessageMe = (
  mailFolderId: MailFolderId,
  messageId: MessageId
) => {
  return {
    type: DELETE_LOCAL_MESSAGE_ME,
    meta: {
      mailFolderId,
      messageId,
    },
  };
};

export const FETCH_MESSAGE_ME_INLINE_IMAGE_REQUEST =
  PRIO + 'FETCH_MESSAGE_ME_INLINE_IMAGE_REQUEST';
export const FETCH_MESSAGE_ME_INLINE_IMAGE_COMMIT =
  PRIO + 'FETCH_MESSAGE_ME_INLINE_IMAGE_COMMIT';
export const FETCH_MESSAGE_ME_INLINE_IMAGE_ROLLBACK =
  PRIO + 'FETCH_MESSAGE_ME_INLINE_IMAGE_ROLLBACK';

export const fetchMessageWithInlineImageMe = (messageId: string) => ({
  type: FETCH_MESSAGE_ME_INLINE_IMAGE_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/email/EmailMe/message/${messageId}/InlineImages`,
        method: 'GET',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: FETCH_MESSAGE_ME_INLINE_IMAGE_COMMIT,
        meta: { messageId },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_MESSAGE_ME_INLINE_IMAGE_ROLLBACK,
        snackbarErrorMessage: {
          label: 'mail:errorMessages.messages.fetchInlineImagesError',
          timeout: 6,
        },
        meta: { messageId },
      },
    },
    messageId,
  },
});

export const FETCH_MESSAGE_ME_DECODE_MIME_COMMIT =
  PRIO + 'FETCH_MESSAGE_ME_DECODE_MIME_COMMIT';

export const commitMessageWithMimeContentMe = (
  messageId: string,
  message: Message
) => ({
  type: FETCH_MESSAGE_ME_DECODE_MIME_COMMIT,
  payload: message,
  meta: {
    messageId,
  },
});

export const PUT_MESSAGE_ME_ATTACHMENT_TO_CACHE =
  PRIO + 'PUT_MESSAGE_ME_ATTACHMENT_TO_CACHE';

export const putMessageMeAttachmentToCache = (
  messageId: string,
  attachment: MessageAttachment[]
) => ({
  type: PUT_MESSAGE_ME_ATTACHMENT_TO_CACHE,
  payload: attachment,
  meta: {
    messageId,
  },
});

export const COPY_MAIL_ME_TO_PROJECT_REQUEST =
  PRIO + 'COPY_MAIL_ME_TO_PROJECT_REQUEST';
export const COPY_MAIL_ME_TO_PROJECT_COMMIT =
  PRIO + 'COPY_MAIL_ME_TO_PROJECT_COMMIT';
export const COPY_MAIL_ME_TO_PROJECT_ROLLBACK =
  PRIO + 'COPY_MAIL_ME_TO_PROJECT_ROLLBACK';

export const copyMessageToProjectMe = (
  messages: Message[],
  mailFolderId: MailFolderId,
  destinationMailFolderId: MailFolderId,
  targetProjectId: ProjectId,
  deleteMail: boolean
) => ({
  type: COPY_MAIL_ME_TO_PROJECT_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/email/EmailMe/messages/copyToProjectAsync/${targetProjectId}`,
        method: 'POST',
        json: {
          destinationMailFolderId,
          copyMessageDtos: messages.map((message) => ({
            messageId: message.id,
            deleteMail: deleteMail ?? false,
            copyAttachments: true,
          })),
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: COPY_MAIL_ME_TO_PROJECT_COMMIT,
        meta: {
          deleteMail,
          mailFolderId,
          destinationMailFolderId,
          messages,
          targetProjectId,
        },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: COPY_MAIL_ME_TO_PROJECT_ROLLBACK,
        meta: {
          deleteMail,
          mailFolderId,
          destinationMailFolderId,
          messages,
          targetProjectId,
        },
        snackbarErrorMessage: {
          label: `mail:errorMessages.messages.${
            messages.length === 1
              ? 'copyMessageToProjectError'
              : 'copyMessagesToProjectError'
          }`,
          timeout: 6,
        },
      },
    },
    deleteMail,
    mailFolderId,
    destinationMailFolderId,
    messages,
    targetProjectId,
  },
});

export interface IMoveMessageMeMeta {
  messageIds: MessageId[];
  destinationId: MailFolderId;
  originId: MailFolderId;
  unreadItemCount: number;
  inboxFolderId: MailFolderId;
}

export const MOVE_MESSAGE_ME_REQUEST = PRIO + 'MOVE_MESSAGE_ME_REQUEST';
export const MOVE_MESSAGE_ME_COMMIT = PRIO + 'MOVE_MESSAGE_ME_COMMIT';
export const MOVE_MESSAGE_ME_ROLLBACK = PRIO + 'MOVE_MESSAGE_ME_ROLLBACK';

export const moveMessageMe: (
  messages: Message[],
  destinationId: MailFolderId,
  originId: MailFolderId,
  inboxFolderId: MailFolderId
) => DispatchAction<IMoveMessageMeMeta, Message[]> = (
  messages,
  destinationId,
  originId,
  inboxFolderId
) => {
  const messageIds = messages.map((message) => message.id);
  const unreadItemCount = messages.filter((message) => !message.isRead).length;
  return {
    type: MOVE_MESSAGE_ME_REQUEST,
    requiresAuth: true,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${apiUrl}/email/EmailMe/message/moveAsync`,
          method: 'POST',
          json: { moveMailDto: { destinationId }, messageIds },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: MOVE_MESSAGE_ME_COMMIT,
          meta: {
            messageIds,
            destinationId,
            originId,
            unreadItemCount,
            inboxFolderId,
          },
        },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: MOVE_MESSAGE_ME_ROLLBACK,
          snackbarErrorMessage: {
            label: `mail:errorMessages.messages.${
              messageIds.length === 1 ? 'moveMessageError' : 'moveMessagesError'
            }`,
            timeout: 6,
          },
          meta: {
            messageIds,
            destinationId,
            originId,
            unreadItemCount,
            inboxFolderId,
          },
        },
      },
      messageIds,
      destinationId,
      originId,
      unreadItemCount,
      inboxFolderId,
    },
  };
};

export const WS_REVERT_MESSAGE_MOVE_ME = PRIO + 'WS_REVERT_MESSAGE_MOVE_ME';

export const wsRevertMessageMoveMe = (
  destinationId: MailFolderId,
  messageId: MessageId,
  message: Message,
  inboxFolderId: MailFolderId
) => ({
  type: WS_REVERT_MESSAGE_MOVE_ME,
  destinationId,
  messageId,
  message,
  inboxFolderId,
});

export const WS_REVERT_MESSAGE_DELETE_ME = PRIO + 'WS_REVERT_MESSAGE_DELETE_ME';

export const wsRevertMessageDeleteMe = (
  sourceId: MailFolderId,
  messageId: MessageId,
  message: Message
) => ({
  type: WS_REVERT_MESSAGE_DELETE_ME,
  sourceId,
  messageId,
  message,
});

export const ADD_ME_MESSAGE_TO_CACHE = PRIO + 'ADD_ME_MESSAGE_TO_CACHE';
export const addMeMessageToCache = (message: Message) => ({
  type: ADD_ME_MESSAGE_TO_CACHE,
  message,
});
