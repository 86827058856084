import React, { useRef, useState } from 'react';
import Flex from '../../../components/Flex';
import { useParams } from 'react-router-dom';
import { makePrioStyles } from '../../../theme/utils';
import classNames from 'classnames';
import { TimeAndLeaveManagementMenu } from './TimeAndLeaveManagementMenu';
import { Button } from '@prio365/prio365-react-library';
import NavigationBar from '../../../components/NavigationBar';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { openTimeAndLeaveManagementDrawer } from '../../timeAndLeaveManagement/actions';
import TimeRecordsModule, {
  TimeRecordsModuleRef,
} from '../../timeRecords/components/TimeRecordsModule';
import MyAbsencesOverviewWrapper from '../../absences/components/MyAbsencesOverviewWrapper';
import TimeAndLeaveManagementCalendar, {
  TimeAndLeaveManagementCalendarPropsRef,
} from '../../timeAndLeaveManagement/components/Calendar/TimeAndLeaveManagementCalendar';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { getUserMe } from '../../../apps/main/rootReducer';
import { PrioTheme } from '../../../theme/types';
import AnnualSummary from '../../timeAndLeaveManagement/components/AnnualSummary';
import AbsenceTimelinePage from '../../absences/components/AbsenceTimeline/AbsenceTimelinePage';
import useContactsContext from '../../contacts/hooks/useContactsProvider';
import { useQueryClient } from '@tanstack/react-query';
import { useMonthlyClosesToClose } from '../hooks/useMonthlyClosesToClose';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    height: '100%',
  },
  menu: {
    flex: 1,
    maxWidth: theme.old.components.menuMaxWidth,
  },
  content: {
    flex: 1,
    padding: theme.old.spacing.defaultPadding,
    width: '100%',
  },
  mainContent: {
    height: '100%',
    overflow: 'hidden',
  },
  spinContainer: {
    height: 400,
  },
  subcomponent: {
    padding: theme.old.spacing.defaultPadding,
  },
  circularProgressbar: {
    margin: '24px',
    width: '100px',
    '& > div > div > div': {
      marginTop: '-109% !important',
    },
  },
  summary: {
    border: '1px solid lightgrey',
    padding: '12px',
  },
  heading: {
    fontSize: 14,
  },
  horizontalDivider: {
    height: '100%',
    margin: '0px 24px',
  },
}));

interface TimeAndLeaveManagementProps {
  className?: string;
  pathPrefix?: string;
}

const START_TIMEKEEPING = '2023-01';

export const TimeAndLeaveManagement: React.FC<TimeAndLeaveManagementProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { className } = props;
  const { selectedList } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();

  const currentMonth = moment().format('YYYY-MM');
  const selectedMonth = searchParams.get('month');
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors

  const userMe = useSelector(getUserMe);

  const { getContactById } = useContactsContext();

  const userOfficeId = getContactById(userMe?.id)?.officeId;

  const timeRecordsRef = useRef<TimeRecordsModuleRef>(null);

  const monthlyCloseCalendarRef =
    useRef<TimeAndLeaveManagementCalendarPropsRef>(null);

  const [employeeConfirmation, setEmployeeConfirmation] = useState<
    undefined | boolean
  >(undefined);

  const { nextToClosableMonthlyClose } = useMonthlyClosesToClose(
    userMe.id,
    queryClient
  );

  const monthToClose = moment(nextToClosableMonthlyClose?.month).format(
    'YYYY-MM'
  );

  const isFutureMonth = moment(selectedMonth).isAfter(currentMonth, 'month');
  const isMonthClosable = moment(selectedMonth).isSame(monthToClose, 'month');
  const isMonthlyCloseButtonDisabled = moment(selectedMonth).isBefore(
    START_TIMEKEEPING,
    'month'
  );
  const isClosedMonth = moment(selectedMonth).isBefore(monthToClose, 'month');
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const showTimeRecordDrawer = () =>
    dispatch(openTimeAndLeaveManagementDrawer({ tab: 'timeRecords' }));

  const createNewAbsenceProposal = () =>
    dispatch(openTimeAndLeaveManagementDrawer({ tab: 'absence' }));

  const createTimeKeepingDay = () =>
    dispatch(openTimeAndLeaveManagementDrawer({ tab: 'timeKeeping' }));
  //#endregion

  //#region ------------------------------ Components
  const renderComponent = () => {
    let component: React.ReactNode;
    switch (selectedList) {
      case 'summary': {
        component = (
          <TimeAndLeaveManagementCalendar
            ref={monthlyCloseCalendarRef}
            onEmployeeConfirmationChange={setEmployeeConfirmation}
            isClosedMonth={isClosedMonth}
            isMonthClosable={isMonthClosable}
          />
        );
        break;
      }
      case 'annualsummary': {
        component = (
          <Flex.Column className={classes.subcomponent} flex={1}>
            <AnnualSummary />
          </Flex.Column>
        );
        break;
      }
      case 'timeRecords': {
        component = (
          <TimeRecordsModule
            contextType="me"
            ref={timeRecordsRef}
            isNavBarUnvisible={true}
          />
        );
        break;
      }
      case 'absences': {
        component = (
          <Flex.Column className={classes.subcomponent} flex={1}>
            <MyAbsencesOverviewWrapper />
          </Flex.Column>
        );
        break;
      }
      case 'absencesOverview': {
        component = <AbsenceTimelinePage officeId={userOfficeId} />;
        break;
      }
      default: {
      }
    }
    return component;
  };

  let navigationItems: React.ReactNode;
  switch (selectedList) {
    case 'timeRecords': {
      navigationItems = (
        <>
          <Button onClick={showTimeRecordDrawer} iconProp={['fal', 'pencil']}>
            <span>{t('common:navigationBar.recordTime')}</span>
          </Button>
        </>
      );
      break;
    }
    case 'absences': {
      navigationItems = (
        <>
          <Button
            onClick={createNewAbsenceProposal}
            iconProp={['fal', 'island-tropical']}
          >
            <span>{t('absences:navigationBar.createNewAbsenceProposal')}</span>
          </Button>
        </>
      );
      break;
    }
    case 'summary': {
      navigationItems = (
        <>
          <Button onClick={createTimeKeepingDay} iconProp={['fal', 'pen']}>
            {t('timeAndLeaveManagement:navigationBar.createTimeKeeping')}
          </Button>
          {employeeConfirmation !== undefined && (
            <Button
              iconProp={['fal', `calendar-check`]}
              onClick={() => {
                monthlyCloseCalendarRef?.current?.confirmCurrentMonthlyClose();
              }}
              disabled={isMonthlyCloseButtonDisabled || isFutureMonth}
              type={'default'}
            >
              {t(
                `timeAndLeaveManagement:navigationBar.${
                  isMonthClosable
                    ? 'confirmMonthlyClose'
                    : 'showDetailsMonthlyClose'
                }`
              )}
            </Button>
          )}
        </>
      );
      break;
    }
    default: {
      navigationItems = null;
      break;
    }
  }
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      <NavigationBar children={navigationItems} />
      <Flex.Row flex={1} className={classes.mainContent}>
        <TimeAndLeaveManagementMenu
          selectedList={selectedList}
          className={classes.menu}
        />
        {/* To improve render performance, AbsenceOverviewPage is provided directly instead of by renderComponent function*/}
        {renderComponent()}
      </Flex.Row>
    </Flex.Column>
  );
};

export default TimeAndLeaveManagement;
