import React, { useCallback, useContext, useState } from 'react';
import classNames from 'classnames';
import { PrioTheme } from '../../../../theme/types';
import { makePrioStyles } from '../../../../theme/utils';
import {
  ContactCompanyData,
  ContactCompanysCalculatedData,
  ContactSearchResultItem,
} from '../../../../models/Contact';
import useContactsContext from '../../hooks/useContactsProvider';
import useCompaniesContext from '../../../companies/hooks/useCompaniesContext';
import useFilterContext from '../../../../components/Filter/hooks/useFilterContext';
import { Column } from '@prio365/prio365-react-library/lib/VirtualTable/components/VirtualTable';
import { sortContactsHelper } from '../../utils';
import UserAvatar from '../../../../components/UserAvatar';
import { FilterBar } from '../../../../components/Filter/FilterBar';
import FilterContextVirtualTable from '../../../../components/Filter/FilterContextVirtualTable';
import FilterResultNoItemsScreen from '../../../../components/Filter/FilterResultNoItemsScreen';
import Flex from '../../../../components/Flex';
import { Button, Empty, VirtualTable } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'react-jss';
import { ContactId } from '../../../../models/Types';
import AddInternalContactDrawerContext from './AddInternalContactDrawerContext';
import { Office } from '../../../../models/Office';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.small,
  },
  body: { flex: 1 },
  content: { height: '100%', display: 'flex', flexDirection: 'column' },
  tablesWrapper: {
    flex: 1,
    display: 'flex',
    gap: theme.spacing.small,
  },
  tableWrapper: {
    flex: 1,
    flexDirection: 'column',
    display: 'flex',
    gap: theme.spacing.small,
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  header: {
    fontSize: theme.font.fontSize.small,
    fontWeight: theme.font.fontWeight.bold,
    color: theme.colors.application.typography.default,
  },
  buttonWrapper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: theme.spacing.small,
  },
  informationBox: {
    marginTop: theme.spacing.small,
  },
  isAlreadyInProject: {
    cursor: 'not-allowed',
    position: 'relative',
    opacity: 0.7,
    '&::before': {
      position: 'absolute',
      content: '""',
      top: 2,
      bottom: 2,
      left: 0,
      width: 5,
      backgroundColor: theme.colors.base.green.default,
    },
  },
  isAlreadySelected: {
    cursor: 'not-allowed',
    position: 'relative',
    opacity: 0.7,
    '&::before': {
      position: 'absolute',
      content: '""',
      top: 2,
      bottom: 2,
      left: 0,
      width: 5,
      backgroundColor: theme.colors.base.primary.default,
    },
  },
  legendItem: {
    borderTop: `1px solid ${theme.colors.application.border}`,
    borderBottom: `1px solid ${theme.colors.application.border}`,
    '& > div': {
      padding: `${theme.spacing.small}px 0px ${theme.spacing.small}px ${theme.spacing.regular}px`,
    },
  },
  label: {
    paddingTop: theme.spacing.regular,
    fontSize: theme.font.fontSize.extraSmall,
    fontWeight: theme.font.fontWeight.bold,
    color: theme.colors.application.typography.muted,
  },
  legendRow: {
    marginTop: theme.spacing.small,
    marginBottom: theme.spacing.regular,
    width: '300px',
  },
}));

interface AddInternalContactDrawerUserSelectStepProps {
  className?: string;
  setDrawerVisible: (visible: boolean) => void;
  setCurrentStep: (step: number) => void;
  existingProjectMembersContactIds: ContactId[];
}

export const AddInternalContactDrawerUserSelectStep: React.FC<
  AddInternalContactDrawerUserSelectStepProps
> = (props) => {
  //#region ------------------------------ Defaults
  const {
    className,
    setDrawerVisible,
    setCurrentStep,
    existingProjectMembersContactIds,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();

  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { selectedContacts, setSelectedContacts } = useContext(
    AddInternalContactDrawerContext
  );

  const { getContactById } = useContactsContext();
  const { getCompanyById } = useCompaniesContext();

  const { data, isLoading } = useFilterContext<
    ContactCompanyData,
    ContactCompanysCalculatedData
  >();

  const contacts = data?.items.sort((a, b) => {
    const contactA = getContactById(a.data.contact.contactId);
    const contactB = getContactById(b.data.contact.contactId);
    return sortContactsHelper(contactA, contactB);
  });
  const [contactsToAdd, setContactsToAdd] = useState<ContactSearchResultItem[]>(
    []
  );
  const [contactsToRemove, setContactsToRemove] = useState<
    ContactSearchResultItem[]
  >([]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const onAddSelectionChange = (selectedItems: ContactSearchResultItem[]) => {
    setContactsToAdd(selectedItems);
  };

  const onRemovalSelectionChange = (
    selectedItems: ContactSearchResultItem[]
  ) => {
    setContactsToRemove(selectedItems);
  };
  const onAddContactsToSelection = () => {
    const newSelectedContacts = contactsToAdd.filter(
      (newContact) =>
        !selectedContacts.some(
          (selectedContact) =>
            selectedContact.data?.contact?.contactId ===
            newContact.data?.contact?.contactId
        ) &&
        !existingProjectMembersContactIds.includes(
          newContact.data?.contact?.contactId
        )
    );

    setSelectedContacts([...selectedContacts, ...newSelectedContacts]);
    setContactsToAdd([]);
  };

  const onRemoveContactsFromSelection = () => {
    setSelectedContacts(
      selectedContacts.filter((item) => !contactsToRemove.includes(item))
    );
    setContactsToRemove([]);
  };

  const handleOnClose = () => {
    setDrawerVisible(false);
    setCurrentStep(0);
    setSelectedContacts([]);
  };

  const handleOnSearchTableRow = useCallback(
    (contact: ContactSearchResultItem) => {
      return {
        onClick: () => {
          const checkIfInSelectedContacts = selectedContacts?.includes(contact);
          const checkIfInExistingProjectMembersContactIds =
            existingProjectMembersContactIds.includes(
              contact.data?.contact?.contactId
            );
          if (
            !checkIfInSelectedContacts &&
            !checkIfInExistingProjectMembersContactIds
          ) {
            setSelectedContacts([...selectedContacts, contact]);
          }
        },
        className: classNames({
          [classes.isAlreadyInProject]:
            existingProjectMembersContactIds.includes(
              contact.data?.contact?.contactId
            ),
          [classes.isAlreadySelected]: !!selectedContacts.find(
            (con) =>
              con.data.contact.contactId === contact.data.contact.contactId
          ),
        }),
      };
    },
    [
      selectedContacts,
      setSelectedContacts,
      existingProjectMembersContactIds,
      classes,
    ]
  );

  const handleOnSelectionTableRow = useCallback(
    (contact: ContactSearchResultItem) => {
      return {
        onClick: () => {
          setSelectedContacts(selectedContacts.filter((c) => c !== contact));
        },
      };
    },
    [selectedContacts, setSelectedContacts]
  );
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  //#region ------------------------------ Column
  const columns: Column<ContactSearchResultItem>[] = [
    {
      id: 'contactId',
      accessor: 'data.contact.contactId',
      title: t('contacts:projectContactsPage.columns.name'),
      width: 100,
      sortingFn: (rowA, rowB) =>
        sortContactsHelper(
          getContactById(rowA.data?.contact?.contactId),
          getContactById(rowB.data?.contact?.contactId)
        ),
      isDefaultSortingFn: true,
      Cell: ({
        originalData: {
          data: { contact },
          calculated: { name },
        },
      }) => (
        <>
          <UserAvatar
            contact={getContactById(contact?.contactId)}
            backgroundColor="#1a2f41"
            size="small"
          ></UserAvatar>
          <div
            style={{
              marginLeft: theme.spacing.small,
              flex: 1,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            <div style={{ fontSize: '14px' }}>{name}</div>
            <div style={{ fontSize: '10px', fontWeight: '400' }}>
              {getCompanyById(contact?.companyId)?.fullName}
            </div>
          </div>
        </>
      ),
      alignSelf: true,
    },
  ];
  //#endregion

  const hiddenPickers = [
    'Data.CompanyId',
    'Calculated.InternalExternal',
    'Calculated.CompanyContact',
    'Data.FirstName',
    'Data.LastName',
    'Calculated.Signature',
    'Data.CommercialRegisterNumber',
    'Data.VATNumber',
    'Data.IsArchived',
  ];

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.body}>
        <div className={classes.content}>
          <FilterBar
            hiddenPickers={hiddenPickers}
            customAlwaysVisiblePickers={[
              'Data.OfficeId',
              'Transformed.ProjectIds',
              'Calculated.Name',
            ]}
            pickerFilterFunctions={{
              office: (office: Office) => {
                return office.officeType === 'internalOffice';
              },
            }}
          />
          <div className={classes.tablesWrapper}>
            <div className={classes.tableWrapper}>
              <div className={classes.header}>
                {t(
                  'contacts:addInternalContactDrawer.userSelectStep.searchTable.header'
                )}
              </div>
              <FilterContextVirtualTable<ContactSearchResultItem>
                id={'AddInternalContactDrawerSearchTable'}
                className={classNames(classes.table)}
                columns={columns}
                data={contacts ?? []}
                selectedItems={contactsToAdd}
                resizable="relative"
                onSelectionChange={onAddSelectionChange}
                onCheckEquality={({ calculated: a }, { calculated: b }) =>
                  a.name === b.name
                }
                noItemsScreen={<FilterResultNoItemsScreen />}
                loading={
                  isLoading && {
                    type: 'noItems',
                  }
                }
                rowsAreSelectable
                shortActionBarPrefix
                onRow={handleOnSearchTableRow}
              />
            </div>
            <div className={classes.buttonWrapper}>
              <Button
                type={contactsToAdd.length === 0 ? 'default' : 'primary'}
                disabled={contactsToAdd.length === 0}
                onClick={onAddContactsToSelection}
                iconProp={['fal', 'chevron-double-right']}
                tooltip={t(
                  'contacts:addInternalContactDrawer.userSelectStep.searchTable.add'
                )}
              />
              <Button
                type={contactsToRemove.length === 0 ? 'default' : 'primary'}
                disabled={contactsToRemove.length === 0}
                onClick={onRemoveContactsFromSelection}
                iconProp={['fal', 'chevron-double-left']}
                tooltip={t(
                  'contacts:addInternalContactDrawer.userSelectStep.selectionTable.remove'
                )}
                tooltipPosition="bottom"
              />
            </div>
            <div className={classes.tableWrapper}>
              <div className={classes.header}>
                {t(
                  'contacts:addInternalContactDrawer.userSelectStep.selectionTable.header'
                )}
              </div>
              <VirtualTable<ContactSearchResultItem>
                id={'AddInternalContactDrawerSelectionTable'}
                columns={columns}
                data={selectedContacts}
                selectedItems={contactsToRemove}
                resizable="relative"
                onSelectionChange={onRemovalSelectionChange}
                onCheckEquality={({ calculated: a }, { calculated: b }) =>
                  a.name === b.name
                }
                noItemsScreen={
                  <Empty
                    customMessage={t(
                      'contacts:addInternalContactDrawer.userSelectStep.selectionTable.noItemsLabel'
                    )}
                  />
                }
                rowsAreSelectable
                shortActionBarPrefix
                onRow={handleOnSelectionTableRow}
              />
            </div>
          </div>
          {!isLoading && (
            <>
              <div className={classes.label}>
                {t(
                  'contacts:addInternalContactDrawer.userSelectStep.searchTable.legend'
                )}
              </div>
              <Flex.Row className={classes.legendRow} childrenGap={8}>
                <Flex.Item flex={1} className={classes.legendItem}>
                  <div className={classes.isAlreadyInProject}>
                    {t(
                      'contacts:addInternalContactDrawer.userSelectStep.searchTable.alreadyInProject'
                    )}
                  </div>
                </Flex.Item>
                <Flex.Item flex={1} className={classes.legendItem}>
                  <div className={classes.isAlreadySelected}>
                    {t(
                      'contacts:addInternalContactDrawer.userSelectStep.searchTable.alreadySelected'
                    )}
                  </div>
                </Flex.Item>
              </Flex.Row>
            </>
          )}
        </div>
      </div>
      <Flex.Row childrenGap={theme.spacing.small} justifyContent="flex-end">
        <Button type="default" onClick={handleOnClose}>
          {t('common:actions.cancel')}
        </Button>
        <Button
          disabled={selectedContacts.length === 0}
          onClick={() => setCurrentStep(1)}
        >
          {t('common:actions.next')}
        </Button>
      </Flex.Row>
    </div>
  );
};

export default AddInternalContactDrawerUserSelectStep;
