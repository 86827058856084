import { ContactTag } from './Contact';
import {
  CreateExternalOfficeRequest,
  CreateInternalOfficeRequest,
  ExternalOffice,
  Office,
} from './Office';
import { CompanyId, CompanyType, BankAccountId, ContactId } from './Types';

export interface Company {
  companyId: CompanyId;
  shortName: string;
  fullName: string;
  fullName2?: string;
  website?: string;
  notes?: string;
  company: {
    offices?: Office[];
  };
  offices?: Office[];
  companyType: CompanyType;
  parentCompanyId?: CompanyId;
  bankAccounts?: BankAccount[];
  rowVersion?: string;
  isArchived?: boolean;
  contactTagDtos?: ContactTag[];
}

export interface InternalCompany extends Company {
  vatNumber?: string;
  commercialRegisterNumber?: string;
  commercialRegisterCity?: string;
  legalForm?: string;
  managerIds?: ContactId[];
  isClient?: boolean;
  isServiceProvider?: boolean;
  companyClassificationName?: string;
  companyClassificationDescription?: string;
}

export interface ExternalCompany extends Company {
  isClient?: boolean;
  isServiceProvider?: boolean;
  companyClassificationName?: string;
  companyClassificationDescription?: string;
}

export interface BankAccount {
  companyId?: CompanyId;
  bankAccountId: BankAccountId;
  creditInstitutionName: string;
  bic: string;
  iban: string;
  rowVersion?: string;
}

export interface CreateExternalCompanyRequest {
  shortName: string;
  fullName: string;
  fullName2?: string;
  Company: {
    offices: CreateExternalOfficeRequest[];
  };
  offices: CreateExternalOfficeRequest[];
  isClient: boolean;
  isServiceProvider: boolean;
  notes?: string;
  companyClassificationName?: string;
}

export interface CreateExternalCompanyFormsResult {
  Company: {
    shortName: string;
    fullName: string;
    fullName2?: string;
    Company: {
      offices: ExternalOffice[];
    };
    offices: ExternalOffice[];
    isClient: boolean;
    isServiceProvider: boolean;
    notes?: string;
    companyClassificationName?: string;
  };
}

export const emptyCreateCompanyRequest: CreateExternalCompanyRequest = {
  shortName: '',
  fullName: '',
  isClient: false,
  isServiceProvider: false,
  Company: {
    offices: [],
  },
  offices: [
    {
      eMail: '',
      officeType: 'externalOffice',
    },
  ],
};

export interface CreateInternalCompanyRequest {
  shortName: string;
  fullName: string;
  fullName2?: string;
  website?: string;
  notes?: string;
  parentCompanyId?: CompanyId;
  offices: CreateInternalOfficeRequest[];
  bankAccounts?: BankAccount[];
}

export const emptyCreateInternalCompanyRequest: CreateInternalCompanyRequest = {
  shortName: '',
  fullName: '',
  offices: [
    {
      eMail: '',
      officeType: 'internalOffice',
    },
  ],
};

export interface UpdateInternalCompanyRequest {
  shortName?: string;
  fullName?: string;
  fullName2?: string;
  phone?: string;
  eMail?: string;
  website?: string;
  street?: string;
  zip?: string;
  city?: string;
  countryCode?: string;
  notes?: string;
  companyClassificationName?: string;
  vatNumber?: string;
}
//BankAccounts interfaces erstellen (Normale Objekt, Update)
export interface UpdateExternalCompanyRequest {
  shortName?: string;
  fullName?: string;
  fullName2?: string;
  phone?: string;
  eMail?: string;
  website?: string;
  street?: string;
  zip?: string;
  city?: string;
  countryCode?: string;
  notes?: string;
  isClient?: boolean;
  isServiceProvider?: boolean;
}
export interface CompanyDuplicate {
  shortName?: string;
  fullName?: string;
}

export interface CreateaBankAccountRequest {
  bankAccountId?: BankAccountId;
  companyId: CompanyId;
  creditInstitutionName: string;
  bic: string;
  iban: string;
  rowVersion?: string;
  contactId?: ContactId;
}

export interface UpdateBankAccountRequest {
  bankAccountId: BankAccountId;
  companyId: CompanyId;
  creditInstitutionName?: string;
  bic?: string;
  iban?: string;
  rowVersion?: string;
  contactId?: ContactId;
}
