import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { notification, Select } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { apiMessageAddCategories, apiMessageRemoveCategories } from '../../api';
import Flex from '../../../../components/Flex';
import { Message } from '../../../../models/Message';
import { MailCategoryColorName, ProjectId } from '../../../../models/Types';
import { makePrioStyles } from '../../../../theme/utils';
import { categorizedMessage } from '../../actions/actionControllers/messageActionController';
import { colorFromPreset } from '../../util';
import useDebounce from '../../../../hooks/useDebounce';
import CategoryTag from './CategoryTag';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  menuItem: {
    minHeight: 48,
    lineHeight: '48px',
  },
  selectIcon: {
    marginRight: theme.old.spacing.unit(2),
  },
  tag: {
    height: 20,
    border: 'none',
    borderRadius: 10,
    fontSize: theme.old.typography.fontSize.small,
    alignItems: 'center',
    '&.ant-tag': {
      padding: `0 ${theme.old.spacing.unit(1)}px`,
    },
    display: 'flex',
  },
  select: {
    width: '100%',
    '& .ant-select-selection-item-content': {
      lineHeight: 1.5715,
    },
  },
  closeIconNoMargin: {
    '& .ant-tag-close-icon': {
      margin: 0,
      opacity: 0,
    },
    '&:hover': {
      '& .ant-tag-close-icon': {
        opacity: 1,
      },
    },
  },
  categoryButton: {
    cursor: 'pointer',
    color: theme.old.palette.primaryColor,
  },
}));

interface MailCategoriesSelectorProps {
  className?: string;
  projectId: ProjectId;
  message?: Message;
  maxCount?: number | 'responsive';
  onChange?: (value: string | string[]) => void;
  colorMap: {
    [displayName: string]: MailCategoryColorName;
  };
}

export const MailCategoriesSelector: React.FC<MailCategoriesSelectorProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { className, message, projectId, maxCount, onChange, colorMap } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const values = message?.categories ?? [];

  const entries = Object.entries(colorMap ?? []);

  const [open, setOpen] = useState<boolean>(false);

  const debouncedOpen = useDebounce(open, 500);

  const ref = useRef(null);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const resetProcessingStateWithTimeout = () => {
    setTimeout(() => {
      setIsProcessing(false);
    }, 400);
  };

  const handleSelect = async (value) => {
    if (isProcessing) return;
    setIsProcessing(true);
    if (message) {
      const { result } = await apiMessageAddCategories(
        projectId,
        [message.id],
        [value]
      );
      if (result.status >= 200 && result.status < 300) {
        dispatch(
          categorizedMessage(
            projectId,
            [message].map((m) => ({
              messageId: m.id,
              categories: [value],
            })),
            false
          )
        );
        if (onChange) {
          onChange([...message.categories, value]);
        }
      } else {
        notification.open({
          message: t('common:error'),
          description: t(
            'mail:errorMessages.messages.updateMailCategoriesError'
          ),
        });
      }
    }
    resetProcessingStateWithTimeout();
  };

  const handleDeselect = async (value: string) => {
    if (isProcessing) return;
    setIsProcessing(true);
    const indx = values.findIndex((category) => category === value);
    if (indx > -1) {
      if (message) {
        const { result } = await apiMessageRemoveCategories(
          projectId,
          [message.id],
          [value]
        );
        if (result.status >= 200 && result.status < 300) {
          dispatch(
            categorizedMessage(
              projectId,
              [message].map((m) => ({
                messageId: m.id,
                categories: [value],
              })),
              true
            )
          );
          const nextCategories = message.categories;
          nextCategories.splice(indx, 1);
          if (onChange) {
            onChange([...nextCategories]);
          }
        } else {
          notification.open({
            message: t('common:error'),
            description: t(
              'mail:errorMessages.messages.updateMailCategoriesError'
            ),
          });
        }
      }
    }
    resetProcessingStateWithTimeout();
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div className={classNames(classes.root, className)}>
      <Flex.Row alignItems="center" childrenGap={20}>
        <div
          className={classes.categoryButton}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            try {
              if (!open && open === debouncedOpen) {
                ref?.current?.focus();
              }
            } catch {}
          }}
        >
          <FontAwesomeIcon icon={['fal', 'plus-circle']} />
        </div>
        <Select
          mode={'multiple'}
          ref={ref}
          bordered={false}
          value={values}
          tagRender={(props) => <CategoryTag {...props} colorMap={colorMap} />}
          className={classes.select}
          onSelect={handleSelect}
          onDeselect={handleDeselect}
          onBlur={() => {
            setOpen(false);
          }}
          onFocus={() => {
            setOpen(true);
          }}
          onClick={(e) => {
            if (!open) {
              setOpen(true);
            }
            e.stopPropagation();
            e.preventDefault();
          }}
          open={open}
          autoFocus={open}
          maxTagCount={maxCount}
          dropdownMatchSelectWidth={250}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Button
                style={{ borderTop: theme.old.borders.content, width: '100%' }}
                iconProp={['fal', 'sync']}
                type="link"
              >
                {t('mail:loadCategories')}
              </Button>
            </>
          )}
        >
          {entries.map(([displayName, color]) => (
            <Select.Option value={displayName} key={displayName}>
              <Flex.Row alignItems="baseline">
                <FontAwesomeIcon
                  icon={['fas', 'tag']}
                  color={colorFromPreset(color, theme)}
                  className={classes.selectIcon}
                />
                <Flex.Item flex={1}>{displayName}</Flex.Item>
              </Flex.Row>
            </Select.Option>
          ))}
        </Select>
      </Flex.Row>
    </div>
  );
};

export default MailCategoriesSelector;
