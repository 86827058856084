import React, { forwardRef, useCallback, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VirtualListItemOnRowProps } from '@prio365/prio365-react-library/lib/VirtualList/components/VirtualListItem';
import FilterContextVirtualTable from '../../../components/Filter/FilterContextVirtualTable';
import FilterResultNoItemsScreen from '../../../components/Filter/FilterResultNoItemsScreen';
import useFilterContext from '../../../components/Filter/hooks/useFilterContext';
import { OptimisticWriteFunction } from '../../../components/Filter/types';
import { makePrioStyles } from '../../../theme/utils';
import { Column } from '@prio365/prio365-react-library/lib/VirtualTable/components/VirtualTable';
import { useQuery } from '@tanstack/react-query';
import { apiFetchContactTags } from '../api';
import {
  ContactCompanyData,
  ContactCompanysCalculatedData,
  ContactSearchResultItem,
} from '../../../models/Contact';
import { useExportContactCompaniesToExcelFile } from '../export';

const useStyles = makePrioStyles((theme) => ({
  root: {
    flex: 1,
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
  },
  cellCentered: {
    justifyContent: 'center',
  },
  row: {
    cursor: 'pointer',
    '& button': {
      visibility: 'hidden',
    },
    '&:hover button': {
      visibility: 'visible',
    },
  },
}));

export interface ContactSearchPageTableRef {
  getSelectedContacts: () => ContactSearchResultItem[];
  optimisticWrite: OptimisticWriteFunction<
    ContactCompanyData,
    ContactCompanysCalculatedData
  >;
}

interface ContactSearchPageTableProps {
  className?: string;
  tableId: string;
  onRowClick?: (entry: ContactSearchResultItem) => void;
  onRowSelectionChange?: (
    selectedSearchItems: ContactSearchResultItem[]
  ) => void;
  selectedSearchItems: ContactSearchResultItem[];
}

export const ContactSearchPageTable = forwardRef(
  (
    props: ContactSearchPageTableProps,
    ref: React.Ref<ContactSearchPageTableRef>
  ) => {
    //#region ------------------------------ Defaults
    const {
      className,
      tableId,
      selectedSearchItems,
      onRowClick,
      onRowSelectionChange,
    } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    //#endregion

    //#region ------------------------------ States / Attributes / Selectors
    const { data, isLoading, optimisticWrite } = useFilterContext<
      ContactCompanyData,
      ContactCompanysCalculatedData
    >();

    const { data: contactTags } = useQuery(
      ['contactTags'],
      () => apiFetchContactTags(),
      {
        staleTime: 1000 * 60 * 60 * 20, // 20 hours
      }
    );
    //#endregion

    //#region ------------------------------ Methods / Handlers
    const onSelectionChange = (items: ContactSearchResultItem[]) => {
      if (onRowSelectionChange) {
        onRowSelectionChange(items);
      }
    };

    const handleOnRow: (
      item: ContactSearchResultItem
    ) => VirtualListItemOnRowProps = useCallback(
      (item) => {
        return {
          onClick: (e) => {
            if (onRowClick) {
              onRowClick(item);
            }
          },
          className: classes.row,
        };
      },
      [classes, onRowClick]
    );

    const exportContactCompaniesToExcelFile =
      useExportContactCompaniesToExcelFile();

    const handleOnExportClick = () => {
      exportContactCompaniesToExcelFile(selectedSearchItems || []);
    };

    const checkEquality = (
      a: ContactSearchResultItem,
      b: ContactSearchResultItem
    ) => {
      const aIsContact = a.data?.contact?.contactId;
      const bIsContact = b.data?.contact?.contactId;
      if (aIsContact && bIsContact) {
        return a.data.contact.contactId === b.data.contact.contactId;
      }
      if (!aIsContact && !bIsContact) {
        return a.data.company.companyId === b.data.company.companyId;
      }
      return false;
    };
    //#endregion

    //#region ------------------------------ Columns
    const columns: Column<ContactSearchResultItem>[] = [
      {
        Cell: ({
          originalData: {
            calculated: { companyContact },
          },
        }) => {
          return (
            <div title={companyContact === 'Company' ? 'Company' : 'Contact'}>
              <FontAwesomeIcon
                icon={[
                  'fal',
                  companyContact === 'Company' ? 'building' : 'user',
                ]}
              />
            </div>
          );
        },
        title: () => <div></div>,
        width: 3,
        minWidth: 34,
        id: 'status',
        accessor: 'calculated.companyContact',
        className: classNames(classes.cell, classes.cellCentered),
        sortingFn: (rowA, rowB) => {
          const contactA = rowA.calculated.companyContact;
          const contactB = rowB.calculated.companyContact;
          if (contactA === contactB) return 0;
          if (contactA === 'Contact') return -1;
          return 1;
        },
        alignSelf: true,
      },
      {
        id: 'name',
        width: 12,
        title: t('contacts:searchTable.header.name'),
        accessor: 'calculated.name',
        sortingFn: (a, b) => a.calculated.name.localeCompare(b.calculated.name),
        Cell: ({
          originalData: {
            calculated: { name },
          },
        }) => `${name}`,
        className: classes.cell,
        alignSelf: true,
      },
      {
        title: t('contacts:searchTable.header.email'),
        width: 25,
        id: 'email',
        accessor: 'calculated.email',
        className: classNames(classes.cell),
        sortingFn: (a, b) =>
          a.calculated.email.localeCompare(b.calculated.email),
        Cell: ({
          originalData: {
            calculated: { email },
          },
        }) => `${email}`,
        alignSelf: true,
      },
      {
        Cell: ({
          originalData: {
            calculated: { phone },
          },
        }) => {
          return phone;
        },
        title: t('contacts:searchTable.header.phone'),
        width: 12,
        id: 'phone',
        accessor: 'calculated.phone',
        className: classNames(classes.cell),
        cellTitle: (contact) => {
          return `${contact.calculated.phone}`;
        },
        alignSelf: true,
        sortingFn: (a, b) =>
          a.calculated.phone.localeCompare(b.calculated.phone),
      },
      {
        Cell: ({
          originalData: {
            calculated: { address },
          },
        }) => {
          return address;
        },
        title: t('contacts:searchTable.header.address'),
        width: 18,
        id: 'address',
        accessor: 'calculated.address',
        className: classNames(classes.cell),
        cellTitle: (contact) => {
          return `${contact.calculated.address}`;
        },
        alignSelf: true,
        sortingFn: (a, b) =>
          a.calculated.address.localeCompare(b.calculated.address),
      },
      {
        title: t('contacts:searchTable.header.contactTags'),
        width: 29,
        id: 'contactTags',
        accessor: 'calculated.contactTagIds',
        className: classNames(classes.cell),
        Cell: ({
          originalData: {
            calculated: { contactTagIds },
          },
        }) => {
          const tags = contactTagIds.map((tagId) => {
            const tag = contactTags?.data?.find(
              (t) => t.contactTagId === tagId
            );
            return tag?.name;
          });
          return `${tags.join(', ')}`;
        },
        alignSelf: true,
      },
    ];
    //#endregion

    //#region ------------------------------ Effects
    useImperativeHandle(ref, () => ({
      getSelectedContacts: () => {
        return selectedSearchItems;
      },
      optimisticWrite,
    }));
    //#endregion

    return (
      <FilterContextVirtualTable<ContactSearchResultItem>
        id={tableId}
        className={classNames(classes.root, className)}
        columns={columns}
        data={data?.items || []}
        selectedItems={selectedSearchItems}
        resizable="relative"
        onRow={handleOnRow}
        onSelectionChange={onSelectionChange}
        onCheckEquality={checkEquality}
        noItemsScreen={<FilterResultNoItemsScreen />}
        loading={
          isLoading && {
            type: 'noItems',
          }
        }
        rowsAreSelectable
        actionBarButtons={[
          {
            iconProp: 'file-excel',
            children: t('contacts:searchTable.actions.exportToExcel'),
            onClick: handleOnExportClick,
          },
        ]}
      />
    );
  }
);

export default ContactSearchPageTable;
