import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import ExternalCompanyForm from './ExternalCompanyForm';
import { CreateExternalCompanyRequest } from '../../../models/Company';
import { archiveCompany, createExternalCompany } from '../actions';
import {
  setContactsDrawerState,
  closeContactsDrawer,
} from '../../contacts/actions/drawer';
import { Dropdown, Typography } from 'antd';
import { createTemporaryId } from '../../../util';
import {
  getContactsDrawerState,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { ContactsDrawerState } from '../../contacts/reducers/drawer';
import { CompanyId } from '../../../models/Types';
import { NewContactMenu } from '../../contacts/components/NewContactMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'antd/lib/form/Form';
import classNames from 'classnames';

const useStyles = makePrioStyles((theme) => ({
  root: {
    overflow: 'hidden',
    height: '100%',
    padding: `${theme.old.spacing.unit(3)}px ${theme.old.spacing.unit(3)}px`,
  },
  companyForm: {
    marginTop: theme.old.spacing.unit(4),
  },
  dropdown: {},
  dropDownIcon: {
    marginLeft: theme.old.spacing.unit(0.75),
    height: 20,
    fontSize: 8,
  },
}));

interface CreateCompanyProps {
  className?: string;
  noAvatar?: boolean;
}

export const CreateCompany: React.FC<CreateCompanyProps> = (props) => {
  //#region -------------------------------- Variables
  const classes = useStyles();
  const { className } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = useForm();
  const { open } = useSelector<RootReducerState, ContactsDrawerState>(
    getContactsDrawerState
  );
  //#endregion

  //#region -------------------------------- State declaration
  const [dropDownOpen, setDropDownOpen] = useState<boolean>(false);
  //#endregion

  //#region -------------------------------- Methods
  const onFinish = async (
    value: CreateExternalCompanyRequest,
    companyIdsToArchive?: CompanyId[]
  ) => {
    const temporaryId = createTemporaryId();
    dispatch(
      createExternalCompany(
        {
          ...value,
          offices: [
            {
              ...value.offices[0],
              name: value.fullName,
              isMainOffice: true,
            },
          ],
        },
        temporaryId
      )
    );
    dispatch(
      setContactsDrawerState({
        selectedCompany: temporaryId,
        view: 'companyDetail',
      })
    );
    if (companyIdsToArchive) {
      companyIdsToArchive.forEach((companyId) =>
        dispatch(archiveCompany(companyId))
      );
    }
    return true;
  };

  const onCancel = () => {
    dispatch(closeContactsDrawer());
  };

  const closeDropDown = () => {
    setDropDownOpen(false);
  };
  //#endregion

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      <Dropdown
        visible={dropDownOpen}
        overlay={<NewContactMenu closeDropdown={closeDropDown} />}
        trigger={['click']}
        onVisibleChange={setDropDownOpen}
        className={classes.dropdown}
      >
        <Typography.Title>
          {t('common:company')}
          <FontAwesomeIcon
            icon={['fal', 'chevron-down']}
            size="xs"
            fixedWidth
            flip={dropDownOpen ? 'vertical' : null}
            className={classes.dropDownIcon}
          />
        </Typography.Title>
      </Dropdown>
      <ExternalCompanyForm
        form={form}
        actionLabel={t('companies:newCompany.actionLabel')}
        cancelLabel={t('common:actions.cancel')}
        className={classes.companyForm}
        onFinish={onFinish}
        onCancel={onCancel}
        disableForm={!open}
        avatar
      />
    </Flex.Column>
  );
};

export default CreateCompany;
