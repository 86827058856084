import React, { useEffect, useState } from 'react';
import { Form, Input, Row, Col, Divider } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';

import { makePrioStyles } from '../../../theme/utils';
import {
  defaultPhonePattern,
  defaultPhonePlaceholder,
} from '../../../util/index';

import { emailPattern } from '../../../hooks/useEmailValidation';
import {
  CreateExternalOfficeRequest,
  CreateInternalOfficeRequest,
  Office,
} from '../../../models/Office';
import { CountryPicker } from '../../../components/CountryPicker';
import Flex from '../../../components/Flex';
import { rowGutter, colon } from '../../../util/forms';
import { Configuration } from '../../../models/Configuration';
import { apiFetchConfigurations } from '../../settings/api';
import { ConfigurationKeys } from '../../../models/Types';
import ContactPicker from '../../contacts/components/ContactPicker';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 1024,
  },
  submitButtonFormItem: {
    textAlign: 'right',
    marginBottom: 0,
  },
  actionButtonsRow: {
    // marginTop: theme.old.spacing.unit(2),
  },
  checkbox: {
    marginTop: theme.old.spacing.unit(2),
  },
  alert: {
    marginTop: theme.old.spacing.unit(1),
  },
  tabs: {},
  tabsPane: {},
  button: {
    background: 'transparent',
    padding: 0,
    '&:hover': {
      background: 'transparent',
    },
  },
  divider: {
    marginTop: '12px',
  },
}));

interface OfficeFormProps {
  className?: string;
  initialValues?: Office;
  editMode?: boolean;
  disableActionButton?: boolean;
  disableForm?: boolean;
  actionLabel: string;
  cancelLabel?: string;
  onFinish: (
    value: CreateExternalOfficeRequest | CreateInternalOfficeRequest
  ) => void;
  onCancel?: () => void;
}

export const OfficeForm: React.FC<OfficeFormProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const {
    className,
    initialValues,
    actionLabel,
    cancelLabel,
    onFinish,
    onCancel,
    editMode,
    disableActionButton,
    disableForm,
  } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [configurations, setConfigurations] = useState<Configuration[] | null>(
    null
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const cancel = () => {
    form.resetFields();
    onCancel();
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    form.resetFields();
  }, [initialValues, form]);

  useEffect(() => {
    const loadConfigs = async () => {
      const { data } = await apiFetchConfigurations();
      if (data) setConfigurations(data);
    };
    loadConfigs();
  }, []);
  //#endregion

  return (
    <Form
      className={classNames(classes.root, className)}
      initialValues={initialValues ?? {}}
      form={form}
      onFinish={onFinish}
      layout="vertical"
    >
      <Flex.Row>
        <Flex.Item flex={1}>
          <Row gutter={theme.old.spacing.unit(rowGutter)}>
            <Col span={24}>
              <Form.Item
                name="name"
                colon={colon}
                rules={[
                  {
                    required: true,
                    message: t('companies:form.validation.missingName'),
                  },
                ]}
              >
                <Input
                  disabled={disableForm}
                  placeholder={t('companies:form.labels.name')}
                />
              </Form.Item>
            </Col>
          </Row>
        </Flex.Item>
      </Flex.Row>

      <Row gutter={theme.old.spacing.unit(rowGutter)}>
        <Col span={24}>
          <Form.Item
            name="street"
            label={t('companies:form.labels.street')}
            colon={colon}
          >
            <Input disabled={disableForm} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={theme.old.spacing.unit(rowGutter)}>
        <Col span={8}>
          <Form.Item
            name="zip"
            label={t('companies:form.labels.zip')}
            colon={colon}
          >
            <Input disabled={disableForm} />
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item
            name="city"
            label={t('companies:form.labels.city')}
            colon={colon}
          >
            <Input disabled={disableForm} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={theme.old.spacing.unit(rowGutter)}>
        <Col span={12}>
          <Form.Item
            name="countryCode"
            label={t('companies:form.labels.countryCode')}
          >
            <CountryPicker
              disabled={disableForm}
              placeholder={t('companies:form.placeholder.countryCode')}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="federalStateCode"
            label={t('companies:form.labels.federalStateCode')}
          >
            <Input
              disabled={disableForm}
              placeholder={t('companies:form.placeholder.federalStateCode')}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={theme.old.spacing.unit(rowGutter)}>
        <Col span={24}>
          <Form.Item
            name="eMail"
            label={t('companies:form.labels.eMail')}
            colon={colon}
            validateTrigger="onBlur"
            rules={[
              {
                required: true,
                message: t('companies:form.validation.missingEmail'),
              },
              {
                message: t('companies:form.validation.invalidEmail'),
                pattern: emailPattern,
              },
            ]}
          >
            <Input disabled={editMode || disableForm} type="email" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={theme.old.spacing.unit(rowGutter)}>
        <Col span={24}>
          <Form.Item
            name="phone"
            label={t('companies:form.labels.phone')}
            colon={colon}
            rules={[
              {
                required: true,
                message: t('companies:form.validation.missingPhoneNumber'),
              },
              {
                message: t('companies:form.validation.invalidPhoneNumber'),
                pattern: configurations
                  ? configurations.find(
                      (config) => config.key === ConfigurationKeys.REGEX_PHONE
                    )
                    ? new RegExp(
                        configurations.find(
                          (config) =>
                            config.key === ConfigurationKeys.REGEX_PHONE
                        ).value
                      )
                    : defaultPhonePattern
                  : defaultPhonePattern,
              },
            ]}
          >
            <Input
              disabled={editMode || disableForm}
              placeholder={
                configurations?.find(
                  (config) => config.key === ConfigurationKeys.PLACEHOLDER_PHONE
                )?.value ?? defaultPhonePlaceholder
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider className={classes.divider} />
      <Row>
        <Col span={24}>
          <Form.Item
            name="managerIds"
            label={t('companies:form.labels.managerIds')}
          >
            <ContactPicker
              multiple
              contactType="InternalContact"
              disabled={disableForm}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end" className={classes.actionButtonsRow}>
        <Col span={24}>
          <Form.Item className={classes.submitButtonFormItem}>
            <Flex.Row
              alignItems={'center'}
              justifyContent={'flex-end'}
              childrenGap={theme.old.spacing.unit(2)}
            >
              {onCancel && (
                <Button type="link" onClick={cancel} className={classes.button}>
                  {cancelLabel}
                </Button>
              )}
              <Button htmlType="submit" disabled={disableActionButton}>
                {actionLabel}
              </Button>
            </Flex.Row>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default OfficeForm;
