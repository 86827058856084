import { put } from 'redux-saga/effects';
export function* handleEmailCopyProgressMessage(data: any) {
  try {
    const { objectType } = data;
    yield put({
      type: 'COPY_MAIL_ASYNC',
      meta: {
        messageId: objectType.messageId,
      },
    });
  } catch (innerError) {
    console.error('Error handling EmailCopyProgressMessage:', innerError, data);
  }
}
